
import { defineComponent } from 'vue';
import Header from './components/Header.vue'
import Footer from "./components/Footer.vue";
import Intro from "./components/Intro.vue";
import "./assets/css/global.css";
import Backdrop from "@/components/Backdrop.vue";
import ParkViewer from "@/components/ParkViewer.vue";

export default defineComponent({
  name: 'App',
  components: {
    ParkViewer,
    //Backdrop,
    //Header,
    //Intro,
    // Footer
  },
  data(){
    return {
      introFinished: false,
    }
  }
});
