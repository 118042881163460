<template>
  <div id="container"></div>
</template>

<script>
import {defineComponent} from "vue";
import * as THREE from 'three/build/three.module';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {Sky} from "three/examples/jsm/objects/Sky";
import dat from "three/examples/jsm/libs/dat.gui.module"
import Stats from 'three/examples/jsm/libs/stats.module'
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import * as INTERACTIVE from "three.interactive/build/three.interactive.module";
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

export default defineComponent({
  name: "ParkViewer",
  data(){
    return {

    }
  },
  mounted(){
    init();
    animate();
  }
});


const DEBUGGING_ENABLED = true;
const clock = new THREE.Clock();
let camera, scene, renderer, labelRenderer, scrollCamera, interactionManager,sky,sun;
let composer, effectFXAA, outlinePass, renderPass; // postprocessing vars
let raycaster, mouse = { x: 0, y: 0 }, interactableObjects = [], INTERSECTED, previous;
var stats;

var cameraParams = {
  currentScrollPath : 1,
  currentTargetPath : 1,
  animationView: false,
  cameraHelper: false
};

const skyParams = {
  turbidity: 10,
  rayleigh: 3,
  mieCoefficient: 0.005,
  mieDirectionalG: 0.7,
  elevation: 2,
  azimuth: 180,
  exposure: 0.8
};

const animationParams = {
  idle: true,
  inProgress: false,
  startTime: 0.0,
  duration: 5000
}

/*
  RESOURCE LOADING START
*/
// Managers
const manager = new THREE.LoadingManager();
function loadParkModel(){
  var loader = new GLTFLoader();
  // Load a glTF resource
  loader.load(
      // resource URL
      '/resources/meshes/parcele_fixed.glb',
      // called when the resource is loaded
      function ( gltf ) {
        gltf.scene.traverse(function (child){
          // !IMPORTANT THIS LINE FIXES THE BUG WHERE DUE TO MATERIAL SHARING UNRELATED MESHES ARE HIGHLIGHTED
          if ( child.material ) child.material = child.material.clone();
          const regex = /p([0-9]+-*[0-9]*)([zd])/gm;
          let m = regex.exec(child.name);
          if(m!=null){
            let parcName = m[1];
            //console.log(parcName);
            let parcStatus = m[2];
            //console.log(parcStatus);
            interactableObjects.push(child);
          }
        })
        //console.log(interactableObjects);
        gltf.scene.scale.set(50,50,50);
        gltf.scene.position.x = 2.5;
        gltf.scene.position.y = 0;
        gltf.scene.position.z = -50;
        scene.add( gltf.scene );
      },
      // called while loading is progressing
      function ( xhr ) {
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
      },
      // called when loading has errors
      function ( error ) {
        console.log( 'An error happened' );
      }
  );
}
/*
  RESOURCE LOADING END
*/


/*
  Initialize sky
*/

function initSky() {
  // Add Sky
  sky = new Sky();
  sky.scale.setScalar( 450000 );
  scene.add( sky );
  sun = new THREE.Vector3();
  const uniforms = sky.material.uniforms;
  uniforms[ 'turbidity' ].value = skyParams.turbidity;
  uniforms[ 'rayleigh' ].value = skyParams.rayleigh;
  uniforms[ 'mieCoefficient' ].value = skyParams.mieCoefficient;
  uniforms[ 'mieDirectionalG' ].value = skyParams.mieDirectionalG;
  //TODO Set so it changes according to Current Time
  const phi = THREE.MathUtils.degToRad( 90 - skyParams.elevation );
  const theta = THREE.MathUtils.degToRad( skyParams.azimuth );
  sun.setFromSphericalCoords( 1, phi, theta );
  uniforms[ 'sunPosition' ].value.copy( sun );
  renderer.toneMappingExposure = skyParams.exposure;
}

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize( window.innerWidth, window.innerHeight );
  labelRenderer.setSize( window.innerWidth, window.innerHeight );
}

function onMouseMove( event ) {
  // calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components
  mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
}


/*
  HOOKS START
 */

function init() {
  const container = document.getElementById("container")

  // Overview helper camera
  camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 2000 );
  camera.position.x=0;
  camera.position.y=10;
  camera.position.z=-40;

  // Create scene
  scene = new THREE.Scene();
  scene.background = new THREE.Color( 0xf0f0f0 );

  // Add ambient light
  const ambientLight = new THREE.AmbientLight( 0xcccccc, 0.7 );
  scene.add( ambientLight );

  //Add direction light
  var light = new THREE.DirectionalLight( 0xffffff );
  light.position.set( 0, 0, 1 );
  scene.add( light );

  // Moving camera
  scrollCamera = new THREE.PerspectiveCamera( 84, window.innerWidth / window.innerHeight, 0.01, 1000 );
  scene.add( scrollCamera );

  //Raycaster
  raycaster = new THREE.Raycaster();

  // renderer

  renderer = new THREE.WebGLRenderer( { antialias: true } );
  renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setSize( window.innerWidth, window.innerHeight );

  //Tone mapping
  renderer.outputEncoding = THREE.sRGBEncoding;
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.5;
  container.appendChild( renderer.domElement );

  //Label renderer

  labelRenderer = new CSS2DRenderer();
  labelRenderer.setSize( window.innerWidth, window.innerHeight );
  labelRenderer.domElement.style.position = 'absolute';
  labelRenderer.domElement.style.top = '0px';
  container.appendChild( labelRenderer.domElement );

  // stats
  stats = new Stats(); //TODO Move to debugging enabled section or addGUI()
  container.appendChild( stats.dom );

  // postprocessing

  composer = new EffectComposer( renderer );

  renderPass = new RenderPass( scene, camera );
  composer.addPass( renderPass );

  outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), scene, camera );
  outlinePass.edgeStrength = 3.5;
  outlinePass.visibleEdgeColor.set("#ffffff");
  outlinePass.hiddenEdgeColor.set("#ffffff")
  composer.addPass( outlinePass );


  const textureLoader = new THREE.TextureLoader();
  textureLoader.load( '/resources/textures/tri_pattern.jpg', function ( texture ) {
    outlinePass.patternTexture = texture;
    outlinePass.usePatternTexture=true;
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
  } );
  //effectFXAA = new ShaderPass( FXAAShader );
  //effectFXAA.uniforms[ 'resolution' ].value.set( 1 / window.innerWidth, 1 / window.innerHeight );
  //composer.addPass( effectFXAA );


  //Add Sky
  initSky();


  var controls = new OrbitControls( camera, labelRenderer.domElement );

  window.addEventListener( 'resize', onWindowResize, false );
  window.addEventListener( 'mousemove', onMouseMove, false );

  loadParkModel()

  if(DEBUGGING_ENABLED===true){
    addGUI(true,true,true,true,true);
    visualizePath(viewerScrollPaths[1]);
    visualizePath(viewerScrollPaths[2]);
    visualizePath(viewerScrollPaths[3]);
    visualizePath(targetPaths[1]);
    visualizePath(targetPaths[2]);
    visualizePath(targetPaths[3]);
  }
}

function animate() {
  requestAnimationFrame( animate );
  composer.render();
  labelRenderer.render(scene, cameraParams.animationView === true ? scrollCamera : camera );
  render();
  update();
  TWEEN.update()
  stats.update();
}

function update(){
  raycaster.setFromCamera(mouse, cameraParams.animationView === true ? scrollCamera : camera)

  // create an array containing all objects in the scene with which the ray intersects
  // INTERSECTED = the object in the scene currently closest to the camera
  //		and intersected by the Ray projected from the mouse position

  var intersects = raycaster.intersectObjects(interactableObjects,false);

  if(intersects.length>0){
      if(typeof INTERSECTED === 'undefined'){
        INTERSECTED=intersects[0].object;
        highlightSelected(INTERSECTED);
      }
      else if(INTERSECTED.name!==intersects[0].object.name) {
        //console.log(intersects[0].object.name);
        previous=INTERSECTED;
        INTERSECTED = intersects[0].object;
        removeHighlight(previous);
        highlightSelected(INTERSECTED);
      }
  }
}

function highlightSelected(child){
  //console.log("Dodana tekstura");
  outlinePass.selectedObjects=[child];
  console.log(child.name);
  let initial = new THREE.Color(child.material.emissive.getHex());
  //child.material.emissive.setHex(0xff0000);
  child.material.emissiveIntensity = 0.5;
  let finalVal = new THREE.Color().setHex(0xff0000);
  child.material.emissive.setHex(finalVal.getHex());
  addObjectLabel(child, {"text": "This is a test!"})
  /*
  let tween = new TWEEN.Tween(initial)
      .to({r:finalVal.r, g:finalVal.g, b:finalVal.b}, 250)
      .easing(TWEEN.Easing.Quartic.In)
      .onUpdate(()=>{
        child.material.emissive.setHex(initial.getHex())
      })
  tween.start()
  */

}

function removeHighlight(child){
  //console.log("Maknuta tekstura");
  let initial = new THREE.Color(child.material.emissive.getHex());
  let finalVal = new THREE.Color().setHex(0x00000);
  child.material.emissiveIntensity = 0.5;
  child.material.emissive.setHex(finalVal.getHex());
  removeObjectLabel(child);
  /*
  let tween = new TWEEN.Tween(initial)
      .to({r:finalVal.r, g:finalVal.g, b:finalVal.b}, 50)
      .easing(TWEEN.Easing.Quartic.In)
      .onUpdate(()=>{
        child.material.emissive.setHex(initial.getHex())
      })
  tween.start()
  */

}

function addObjectLabel(object, annotationData){
  const labelDiv = document.createElement( 'div' );
  labelDiv.textContent = annotationData["text"];
  labelDiv.style.marginTop = '-1em';
  labelDiv.style.color="#ffffff"
  labelDiv.style.backgroundColor = "#312c2c";
  const objectLabel = new CSS2DObject( labelDiv );
  let target = new THREE.Vector3();
  object.geometry.boundingBox.getCenter(target);
  objectLabel.position.set( target.x, target.y, 0 );
  console.log(object);
  object.add( objectLabel );
}

function removeObjectLabel(object){
  object.clear();
}

function render() {
  // animate camera along spline

  var time = Date.now();
  var looptime = 10 * 1000;
  var t = ( time % looptime ) / looptime;

  var pos = viewerScrollPaths[cameraParams.currentScrollPath].getPointAt(t);

  //Alternative - Camera looks at fixed position defined by Vector
  /*
  scrollCamera.matrix.lookAt( scrollCamera.position, lookAt, new THREE.Vector3(0,0,1) );
  scrollCamera.rotation.setFromRotationMatrix( scrollCamera.matrix, scrollCamera.rotation.order );
   */
  var lookAtCurve = targetPaths[cameraParams.currentTargetPath].getPointAt( t )

  var lookAt = new THREE.Vector3(-120,20.0,700.0);
  scrollCamera.lookAt(lookAtCurve);
  scrollCamera.position.copy( pos );
  if(DEBUGGING_ENABLED===true){
    cameraHelper.update();
    cameraEye.position.copy( pos );
  }
  renderPass.camera = cameraParams.animationView === true ? scrollCamera : camera;
  outlinePass.renderCamera = cameraParams.animationView === true ? scrollCamera : camera;
  //renderer.render( scene, cameraParams.animationView === true ? scrollCamera : camera );
}


/*
  HOOKS END
 */


/*-------------------------------------------------------
  SCROLL PATHS DEFINITIONS START
-------------------------------------------------------*/

// Control points for the camera (viewer) through which smooth path splines will be pulled
const viewerControlPoints = {
  1: [
    new THREE.Vector3( -120,150.0,450.0), new THREE.Vector3( -120,50.0,350.0),
    new THREE.Vector3( -120,50.0,300.0), new THREE.Vector3( -120,50.0,260.0),
    new THREE.Vector3( -120,50.0,250.0), new THREE.Vector3( -120,150.0,200.0)
    ],
  2: [
    new THREE.Vector3( -120,150.0,200.0), new THREE.Vector3( -120,50.0,100.0),
    new THREE.Vector3( -120,50.0,50.0), new THREE.Vector3( -120,50.0,10.0),
    new THREE.Vector3( -120,50.0,0.0), new THREE.Vector3( -120,150.0,-50.0)
    ],
  3: [
    new THREE.Vector3( -120,150.0,-50.0), new THREE.Vector3( -120,50.0,-150.0),
    new THREE.Vector3( -120,50.0,-200.0), new THREE.Vector3( -120,50.0,-240.0),
    new THREE.Vector3( -120,50.0,-250.0), new THREE.Vector3( -120,150.0,-300.0)
    ],
}

//TODO Dodati tekst
//TODO Obrnuti smjer kretanja
// Spline paths for the camera for each segment
const viewerScrollPaths={
  1: new THREE.CatmullRomCurve3(viewerControlPoints[1]),
  2: new THREE.CatmullRomCurve3(viewerControlPoints[2]),
  3: new THREE.CatmullRomCurve3(viewerControlPoints[3])
}

// Paths where camera(viewer) looks at (target) for each segment
const targetControlPoints = {
  1: [
    new THREE.Vector3( -60,20.0,630.0), new THREE.Vector3( -100,60.0,630.0),
    new THREE.Vector3( -100,60.0,630.0), new THREE.Vector3( -140,60.0,630.0),
  ],
  2: [
    new THREE.Vector3( -60,20.0,630.0), new THREE.Vector3( -100,60.0,630.0),
    new THREE.Vector3( -100,60.0,630.0), new THREE.Vector3( -140,60.0,630.0),

  ],
  3: [
    new THREE.Vector3( -60,20.0,630.0), new THREE.Vector3( -100,60.0,630.0),
    new THREE.Vector3( -100,60.0,630.0), new THREE.Vector3( -140,60.0,630.0),

  ]
}

const targetPaths={
  1: new THREE.CatmullRomCurve3(targetControlPoints[1]),
  2: new THREE.CatmullRomCurve3(targetControlPoints[2]),
  3: new THREE.CatmullRomCurve3(targetControlPoints[3])
}


/*-------------------------------------------------------
  SCROLL PATHS DEFINITIONS END
-------------------------------------------------------*/


/*
-------------------------------------------------------
  DEBUG AND HELPER FUNCTIONS START
-------------------------------------------------------
*/
let cameraHelper, cameraEye;

// Visualize path
function visualizePath(path,col=0x0000ff){
  //Create geometry
  let points = path.getPoints(50);
  let geom = new THREE.BufferGeometry().setFromPoints(points);
  const material = new THREE.LineBasicMaterial({
    color: col
  });
  // Final object to add to scene
  const line = new THREE.Line(geom, material);
  scene.add(line);
}

function updateParams() {
  
  cameraHelper.visible = cameraParams.cameraHelper;
  cameraEye.visible = cameraParams.cameraHelper;

}


function addGUI(geometryGUI=false,skyGUI=false,cameraGUI=false, showAxesHelper=false, showDirectionHelper=false){
  var gui = new dat.GUI( { width: 300 } );


  // Geometry GUI
  if(geometryGUI){
    var folderGeometry = gui.addFolder( 'Geometry' );
    folderGeometry.open();
    folderGeometry.add(cameraParams, "currentScrollPath", Object.keys( viewerScrollPaths ) );
  }

  // Camera GUI
  if(cameraGUI){
    //Add camera helper to see where camera is looking at
    cameraHelper = new THREE.CameraHelper( scrollCamera );
    scene.add( cameraHelper );

    // Add camera eye to visualize camera position
    cameraEye = new THREE.Mesh( new THREE.SphereBufferGeometry( 5 ), new THREE.MeshBasicMaterial( { color: 0xdddddd } ) );
    scene.add( cameraEye );

    //Set params
    cameraHelper.visible = cameraParams.cameraHelper;
    cameraEye.visible = cameraParams.cameraHelper;

    var folderCamera = gui.addFolder( 'Camera' );
    folderCamera.add( cameraParams, 'animationView' );
    folderCamera.add( cameraParams, 'cameraHelper' ).onChange( function (v) {
      console.log(v);
      updateParams();
    } );
    folderCamera.open();
  }

  //Sky GUI
  function skyUpdate() {

    const uniforms = sky.material.uniforms;
    uniforms[ 'turbidity' ].value = skyParams.turbidity;
    uniforms[ 'rayleigh' ].value = skyParams.rayleigh;
    uniforms[ 'mieCoefficient' ].value = skyParams.mieCoefficient;
    uniforms[ 'mieDirectionalG' ].value = skyParams.mieDirectionalG;

    //TODO Set so it changes according to Current Time
    const phi = THREE.MathUtils.degToRad( 90 - skyParams.elevation );
    const theta = THREE.MathUtils.degToRad( skyParams.azimuth );

    sun.setFromSphericalCoords( 1, phi, theta );

    uniforms[ 'sunPosition' ].value.copy( sun );

    renderer.toneMappingExposure = skyParams.exposure;
    renderer.render( scene, camera );
  }

  if(skyGUI) {
    var folderSky = gui.addFolder( 'Sky' );
    folderSky.add( skyParams, 'turbidity', 0.0, 20.0, 0.1 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'rayleigh', 0.0, 4, 0.001 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'mieCoefficient', 0.0, 0.1, 0.001 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'mieDirectionalG', 0.0, 1, 0.001 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'elevation', 0, 90, 0.1 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'azimuth', - 180, 180, 0.1 ).onChange( skyUpdate );
    folderSky.add( skyParams, 'exposure', 0, 1, 0.0001 ).onChange( skyUpdate );
  }

  //Show Direction Helper
  if(showDirectionHelper){
    let dir,origin,length;
    dir = new THREE.Vector3 (-50,20.0,250.0);
    origin = scrollCamera.position;
    length=1.0;
    const arrowHelper = new THREE.ArrowHelper( dir, origin, length= 0xffff00 );
    scene.add( arrowHelper );
  }

  //Show Axes Helper
  if(showAxesHelper){
    const axesHelper = new THREE.AxesHelper( 20 );
    axesHelper.position.set(0,20.0,0);
    scene.add(axesHelper);
  }

}


/*
-------------------------------------------------------
  DEBUG AND HELPER FUNCTIONS END
-------------------------------------------------------
*/

</script>

<style scoped>

#container{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index: -1;
  object-fit: cover;
}

.backdrop-container{
  text-align: center;
  /*
  background-color: rgba(0, 0, 0, 0);
   */
  background-color: rgba(0, 0, 0, 0.0);
  left:50%;
  margin-left:-15em;
  margin-top:-9em;
  position:absolute;
  top:60%;
  width:30em;
  padding:2em;
}

</style>