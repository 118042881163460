<template>
  <!--
  <transition name="dropfade">
    <Header v-if="introFinished"></Header>
  </transition>
  <transition name="fade">
    <Intro @intro-finished="introFinished=true;" v-if="!introFinished"></Intro>
  </transition>
  <Backdrop v-if="introFinished"></Backdrop>
  -->
  <ParkViewer></ParkViewer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Header from './components/Header.vue'
import Footer from "./components/Footer.vue";
import Intro from "./components/Intro.vue";
import "./assets/css/global.css";
import Backdrop from "@/components/Backdrop.vue";
import ParkViewer from "@/components/ParkViewer.vue";

export default defineComponent({
  name: 'App',
  components: {
    ParkViewer,
    //Backdrop,
    //Header,
    //Intro,
    // Footer
  },
  data(){
    return {
      introFinished: false,
    }
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.dropfade-enter-from{
  margin-top:-60px;
  opacity:0;
}
.dropfade-enter-active{
  transition: all 2s ease;
}
.dropfade-enter-to{
  opacity:1;
  margin-top:0;
}
.dropfade-leave-from{
  opacity:1;
  margin-top:0;
}

.dropfade-leave-active{
  transition: all 2s ease;
}
.dropfade-leave-to{
  opacity:0;
  margin-top:-60px;
}

fade-enter-from{
  opacity:0;
}
.fade-enter-to{
  opacity:1.0;
}
.fade-enter-active {
  transition: all 2s ease;
}

fade-leave-from{
  opacity:1.0;
}
.fade-leave-to{
  opacity:0;
}
.fade-leave-active {
  transition: all 2s ease;
}
</style>
